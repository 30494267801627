// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-1-js": () => import("./../../../src/pages/blog1.js" /* webpackChunkName: "component---src-pages-blog-1-js" */),
  "component---src-pages-blog-2-js": () => import("./../../../src/pages/blog2.js" /* webpackChunkName: "component---src-pages-blog-2-js" */),
  "component---src-pages-blog-3-js": () => import("./../../../src/pages/blog3.js" /* webpackChunkName: "component---src-pages-blog-3-js" */),
  "component---src-pages-blog-4-js": () => import("./../../../src/pages/blog4.js" /* webpackChunkName: "component---src-pages-blog-4-js" */),
  "component---src-pages-blog-5-js": () => import("./../../../src/pages/blog5.js" /* webpackChunkName: "component---src-pages-blog-5-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-conditions-js": () => import("./../../../src/pages/conditions.js" /* webpackChunkName: "component---src-pages-conditions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

